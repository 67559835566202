
  import Conversation from 'models/conversation'
  import InboxMessage from 'models/inbox_message'

  import DateBadge from './date_badge.vue'
  import HandoverInterface from './handover_interface.vue'
  import Message from './message.vue'
  import MobileNav from './mobile_nav.vue'
  import TypingAnimation from './typing_animation.vue'

  export default
    props:
      conversation: Conversation
      messages: []

    data: ->
      params: {pageSize: 20}
      loading: false
      finished: false
      threshold: 400 # in pixels (if end of inner container is nearer than threshold, new item will be loaded)
      error: false

    computed:
      lastMessageId: ->
        @conversation?.messages.last?.id
      showHandoverInterface: ->
        @conversation?.isInHandoverMode && @$root.mayAnswerInbox
      filteredMessages: ->
        if @$root.currentTab?.key == 'chat'
          @conversation.messages.filter (message) -> message.origin != 'SYSTEM'
        else
          @conversation.messages

    watch:
      'conversation.id': ->
        return if !@conversation?
        @check()
      lastMessageId: ->
        @keepScrolledToBottom()
      showHandoverInterface: ->
        @keepScrolledToBottom()

    mounted: ->
      @$refs.scrollElement.addEventListener 'scroll', @check

    methods:
      check: ->
        return Promise.resolve() if !@conversation?
        new Promise (resolve, reject) =>
          if @loading || @conversation.finished
            resolve()
          else if @$refs.scrollElement.scrollTop < @threshold
            @load().then(resolve)
          else
            resolve()
      load: ->
        new Promise (resolve, reject) =>
          if @conversation.finished
            resolve()
            return
          @loading = true
          @error = false
          @params.beforeId = @conversation.messages[0]?.id
          conversation = @conversation # avoid race condition when changing conversations quickly
          @$root.api.loadMessages(@conversation, @params)
            .then (data) =>
              @insert(conversation, data).then(resolve)
            .catch =>
              @loading = false
              @error = true
              reject()
      insert: (conversation, data) ->
        newMessages = data.map((data) -> new InboxMessage(data))
        oldOffset = @$refs.scrollElement.scrollTop - @$refs.scrollElement.scrollHeight
        # prepend new messages
        conversation.messages.unshift.apply(conversation.messages, newMessages)
        conversation.finished = if data.finished? then data.finished else newMessages.length < @params.pageSize
        @loading = false
        new Promise (resolve, reject) =>
          Vue.nextTick =>
            # keep scroll position
            @$refs.scrollElement.scrollTop = @$refs.scrollElement.scrollHeight + oldOffset
            @check().then(resolve)
      showDateBadge: (index) ->
        return true if index == 0
        return true if @filteredMessages[index - 1]?.formattedDate != @filteredMessages[index].formattedDate
        false
      keepScrolledToBottom: ->
        scrolledToBottom = @$refs.scrollElement.scrollTop + 20 >= @$refs.scrollElement.scrollHeight - @$refs.scrollElement.offsetHeight
        if scrolledToBottom
          @$nextTick(@scrollToBottom)
      scrollToBottom: ->
        @$refs.scrollElement.scrollTop = @$refs.scrollElement.scrollHeight - @$refs.scrollElement.offsetHeight

    components:
      DateBadge: DateBadge
      Message: Message
      HandoverInterface: HandoverInterface
      MobileNav: MobileNav
      TypingAnimation: TypingAnimation
