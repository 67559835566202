TRANSLATIONS =
  en:
    formats:
      date: 'YYYY-MM-DD'
      time: 'HH:mm'
      duration: 'HH:mm:ss'
      dateTime: 'YYYY-MM-DD HH:mm'
    general:
      channel: 'Channel'
      show: 'Show'
      all: 'all'
      mine: 'mine'
      myTeams: 'my teams’'
      ok: 'Ok'
      cancel: 'Cancel'
      backToList: 'Back to list'
      desktopNotificationPreferences: 'Please set your desktop notification preferences!'
    inbox:
      phaseOutNotice: 'This inbox version is phasing out in February 2024.'
      previewNewInbox: 'Switch to new inbox'
      goToLegacyInbox: 'Switch to legacy inbox'
      reconnecting: 'Inbox is trying to reconnect...'
      tabs:
        inbox: 'Inbox'
        needAnnotation: 'Annotation'
        debugging: 'Debugger'
        conversations: 'All Conversations'
        # for inbox3
        chat: 'Live chat'
        chatDescription: 'All conversations that are currently in handover mode.'
        overview: 'Inbox overview'
        overviewDescription: 'All conversations that have been in handover mode.'
        logs: 'Conversation logs'
        logsDescription: 'All conversations. Not updated in realtime.'
      assignment:
        chat:
          all: 'all live chats'
          mine: 'my live chats'
          myTeams: 'my teams’ live chats'
        overview:
          all: 'all conversations'
          mine: 'my conversations'
          myTeams: 'my teams’ conversations'
        helpTexts:
          assignedToMe: 'assigned to me'
          assignedToOneOfMyTeams: 'assigned to a team colleague'
          assignedToSomeoneElse: 'assigned to some other team'
      filter:
        addFilter: 'Add filter'
        searchName: 'Search by name'
        searchAnswer: 'Search message'
        allTags: 'All tags'
        selectTag: 'Select tag'
      date:
        before: 'Before'
        thisWeek: 'This week'
        yesterday: 'Yesterday'
        today: 'Today'
      conversations:
        assignedTo: 'Assigned to'
        assign: 'Assign'
        selectAgent: 'Select agent'
        defaultAgentName: 'Any Agent'
        defaultAgentFirstname: 'Any'
        defaultAgentLastname: 'Agent'
        deleteUser: 'Delete user'
        exportUserData: 'Export user data'
        newOneWaiting: 'There’s a bot user waiting for an answer!'
        noneInList: 'Nothing needs your attention here'
        noneSelected: 'No conversation selected'
        notes: 'Notes'
        addNote: 'add a note'
        answerTemplates: 'Answer templates'
        addAnswerTemplates: 'Add to answer templates'
        selectAnswerTemplate: 'Select answer template'
        uploadFile: 'Upload file'
        reEngageChatbot: 'End session'
        takeOver: 'Take over conversation'
        buttonSend: 'Send'
        buttonClose: 'Close'
        showAll: 'Show all'
        hide: 'Hide'
        firstSeen: 'First seen'
        lastSeen: 'Last seen'
        totalMessages: 'Total messages'
        statusTexts:
          online: 'the user is currently online'
          idle: 'the user is currently idle'
        user:
          firstName: 'First Name'
          lastName: 'Last name'
          email: 'Email'
          phone: 'Phone'
  de:
    formats:
      date: 'DD.MM.YYYY'
      time: 'HH:mm'
      duration: 'HH:mm:ss'
      dateTime: 'DD.MM.YYYY HH:mm'
    general:
      channel: 'Kanal'
      show: 'anzeigen'
      all: 'alle'
      mine: 'meine'
      myTeams: 'die meiner Teams'
      ok: 'Ok'
      cancel: 'Abbrechen'
      backToList: 'Zur Liste'
      desktopNotificationPreferences: 'Einstellung für Desktop-Benachrichtigungen vornehmen'
    inbox:
      phaseOutNotice: 'Diese Inbox-Version wird im Februar 2024 deaktiviert.'
      previewNewInbox: 'Zur neuen Inbox'
      goToLegacyInbox: 'Zur alten Inbox'
      reconnecting: 'Versuche neu zu verbinden...'
      tabs:
        inbox: 'Posteingang'
        needAnnotation: 'Annotation'
        debugging: 'Debugger'
        conversations: 'Alle Unterhaltungen'
        # for inbox3
        chat: 'Livechat'
        chatDescription: 'Alle Unterhaltungen, die aktuell im Livechat-Modus sind.'
        overview: 'Inboxübersicht'
        overviewDescription: 'Alle Unterhaltungen, die im Livechat-Modus sind oder waren.'
        logs: 'Alle Unterhaltungen'
        logsDescription: 'All Unterhaltungen. Kein Live-Update.'
      assignment:
        chat:
          all: 'alle Livechats'
          mine: 'meine Livechats'
          myTeams: 'Livechats meiner Teams'
        overview:
          all: 'alle Konversationen'
          mine: 'meine Konversationen'
          myTeams: 'Konversationen meiner Teams'
        helpTexts:
          assignedToMe: 'mir zugewiesen'
          assignedToOneOfMyTeams: 'einem Teamkollegen zugewiesen'
          assignedToSomeoneElse: 'einem anderen Team zugewiesen'
      filter:
        addFilter: 'Filter hinzufügen'
        searchName: 'Nach Namen suchen'
        searchAnswer: 'Nachricht durchsuchen'
        allTags: 'Alle Kategorien'
        selectTag: 'Nach Kategorie filtern'
      date:
        before: 'Vorher'
        thisWeek: 'Diese Woche'
        yesterday: 'Gestern'
        today: 'Heute'
      conversations:
        assignedTo: 'Zugewiesen an'
        assign: 'Zuweisen'
        selectAgent: 'Agent auswählen'
        defaultAgentName: 'Automatisch'
        defaultAgentFirstname: 'Automatisch'
        defaultAgentLastname: ''
        deleteUser: 'Nutzer löschen'
        exportUserData: 'Nutzerdaten exportieren'
        newOneWaiting: 'Ein Bot-Nutzer wartet auf eine Antwort!'
        noneInList: 'Keine Konversation vorhanden'
        noneSelected: 'Keine Konversation ausgewählt'
        notes: 'Notizen'
        addNote: 'Notiz hinzufügen'
        answerTemplates: 'Antwortvorlagen'
        addAnswerTemplates: 'Zu Antwortvorlagen hinzufügen'
        selectAnswerTemplate: 'Antwortvorlage auswählen'
        uploadFile: 'Datei hochladen'
        reEngageChatbot: 'Session beenden'
        takeOver: 'Gespräch übernehmen'
        buttonSend: 'Senden'
        buttonClose: 'Schließen'
        showAll: 'Alle anzeigen'
        hide: 'Ausblenden'
        firstSeen: 'Zuerst gesehen'
        lastSeen: 'Zuletzt gesehen'
        totalMessages: 'Nachrichten insgesamt'
        statusTexts:
          online: 'der Nutzer ist online'
          idle: 'der Nutzer ist inaktiv'
        user:
          firstName: 'Vorname'
          lastName: 'Nachname'
          email: 'E-Mail'
          phone: 'Telefon'

window.t = (key, language) ->
  language ||= (Globals.currentUser.language || 'en')
  path = [ language[0..2] ].concat(key.split('.'))
  translation = (path) ->
    text = TRANSLATIONS
    for segment in path
      text = text?[segment]
    text
  text = translation(path)
  if !(typeof text == 'string')
    text = translation(['en'].concat(path[1..-1]))
  text

window.l = (time, format) ->
  momentJSObject = if format == 'duration'
    moment.utc(time)
  else
    moment(time)
  momentJSObject.format(
    t('formats.' + format)
  )

# make functions available to all Vue templates
Vue.mixin
  data: ->
    t: t
