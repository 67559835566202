import Agent from 'models/agent'
import AgentTeam from 'models/agent_team'
import ContentEditableSpan from 'models/content_editable_span'
import Message from 'models/message'
import MessageVariation from 'models/message_variation'
import PlatformApi from 'models/platform_api'
import PlatformApi2 from 'models/platform_api2'

export default class AnswerTemplate
  @list: ->
    PlatformApi.Handover.getAnswerTemplates()
      .then (data) -> data.map (templateData) -> new AnswerTemplate(templateData)

  @availableTags: ->
    PlatformApi.Handover.getAnswerTemplateTags()

  @load: (id) ->
    PlatformApi.Handover.getAnswerTemplate(id)
      .then (data) -> new AnswerTemplate(data)

  constructor: (data = {}) ->
    @exampleQuestion = ''
    @teams = []
    @tags = []
    @setMessageTemplate('text')
    @renderedMessages = {}
    @update(data)

  update: (data) ->
    @id = data.id if data.id?
    @createdBy = new Agent(data.createdBy) if data.createdBy?
    if data.message?
      if data.message.templateType
        @message = new Message(new MessageVariation(), data.message)
        @message.messageVariation.messages = [@message]
      else
        @message = Message.template(new MessageVariation(), 'text')
        @message.messageVariation.messages = [@message]
        if data.message.type == 'Bubble'
          @message.bubbles[0].attachments[0].text = data.message.attachments[0].text
      @message.origin = 'HANDOVER'
    @exampleQuestion = data.exampleQuestion if data.exampleQuestion?
    @teams = data.teams.map((data) -> new AgentTeam(data)) if data.teams?
    @tags = data.tags if data.tags?
    @confidence = data.confidence if data.confidence?
    @timeCreated = data.timeCreated if data.timeCreated?
    @timeLastUsed = data.timeLastUsed if data.timeLastUsed?
    @timeDeleted = data.timeDeleted if data.timeDeleted?
    return this

  setMessageTemplate: (templateKey) ->
    @message = Message.template(new MessageVariation(), templateKey)
    @message.origin = 'HANDOVER'
    @message.messageVariation.messages = [@message]
    @message

  save: =>
    if @id
      PlatformApi.Handover.updateAnswerTemplate(@)
    else
      PlatformApi.Handover.createAnswerTemplate(@).then (data) => @update(data)

  delete: ->
    PlatformApi.Handover.deleteAnswerTemplate(@)

  clone: ->
    new AnswerTemplate(@export)

  render: (conversation) ->
    return if @renderedMessages[conversation.id]?
    PlatformApi2.renderMessage(conversation, @message).then (data) =>
      message = new Message(new MessageVariation(), data)
      message.messageVariation.messages = [message]
      message.origin = 'HANDOVER'
      @renderedMessages[conversation.id] = message

  Object.defineProperties @prototype,
    lowerCaseExampleQuestion:
      get: ->
        @exampleQuestion.toLowerCase()
    bubbles: # still needed for inbox2
      get: ->
        @message.bubbles
    isTextMessage:
      get: ->
        @message.allAttachments.length == 1 && @message.allAttachments[0].type == 'Text'
    isEditable:
      get: ->
        @message.bubbles.length == 1 &&
          @message.allAttachments[0].type == 'Text'
          @message.allAttachments.filter((attachment) -> attachment.type == 'Text').length == 1
    messageText: # still needed for inbox2
      get: ->
        @message.bubbles.map((bubble) ->
          bubble.attachments
            .filter (attachment) -> attachment.type == 'Text'
            .map (attachment) -> attachment.text
            .join('\n')
        ).join('\n')
    valid:
      get: ->
        @teams.length
    export:
      get: ->
        id: @id
        exampleQuestion: @exampleQuestion
        message: @message.export
        teams: @teams.map (team) -> {id: team.id}
        tags: @tags
    parsedMessageText: # still needed for inbox2
      get: ->
        parts = []
        charIndex = 0
        [...@messageText.matchAll(/\$\{(.*?)\}/g)].forEach (match) =>
          precedingText = @messageText[charIndex...match.index]
          parts.push(new ContentEditableSpan(type: 'text', text: precedingText))
          parts.push(new ContentEditableSpan(type: 'expression', text: match[1]))
          charIndex += precedingText.length + match[0].length
        if charIndex < @messageText.length
          parts.push(new ContentEditableSpan(type: 'text', text: @messageText[charIndex..]))
        parts
