
  import Utterance from 'models/utterance.coffee'
  import UtteranceSlot from 'models/utterance_slot.coffee'
  import UtteranceSlotEditor from './utterance_slot_editor.vue'
  import Contenteditable from 'mixins/contenteditable.coffee'
  import { ActionMenu, Button } from 'components/generic'
  import { VariableIcon, XIcon } from '@heroicons/vue/outline'

  export default
    mixins: [Contenteditable]

    props:
      modelValue: Utterance
      slotDefinition: Object
      host: Object
      disabled:
        type: Boolean
        default: false
      hideActionMenu:
        type: Boolean
        default: false
      noNewSlots:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove', 'add']

    data: ->
      utterance: @modelValue
      selectedNodes: []
      startOffset: 0
      endOffset: 0
      buttonActive: false
      debounceTimeout: null
      slotEditorRefs: []
      UtteranceSlot: UtteranceSlot

    computed:
      actionMenuItems: ->
        [
          if @utterance.isRegEx
            {icon: VariableIcon, label: 'Don’t parse as RegEx', method: (=> @utterance.isRegEx = false; @update())}
          else
            {icon: VariableIcon, label: 'Parse as RegEx', method: (=> @utterance.isRegEx = true; @update())}
          {icon: XIcon, label: 'Delete', method: => @$emit('remove')}
        ].filter (item) -> item?

    watch:
      modelValue: ->
        @utterance = @modelValue
        @buildParts()

    created: ->
      @buildParts()
      @catchRevision()

    beforeUpdate: ->
      @slotEditorRefs = []

    beforeUnmount: ->
      return if !@debounceTimeout?
      clearTimeout(@debounceTimeout)
      @updateUtterance()

    methods:
      resetParts: ->
        @parts = ['​']
      addTextPart: (text) ->
        return if text.length == 0 || text == '​'
        @parts.push(text)
      addEmptyTextPart: ->
        @parts.push('​')
      buildParts: ->
        @resetParts()
        charIndex = 0
        @utterance.slots.sort((a, b) -> a.startPos - b.startPos).forEach (slot, index) =>
          if slot.startPos > 0
            string = @utterance.string[charIndex..(slot.startPos - 1)]
            @addTextPart(string)
            charIndex += string.length
          @parts.push(slot)
          charIndex += slot.text.length
        if charIndex < @utterance.string.length
          @addTextPart(@utterance.string[charIndex..])
        @addEmptyTextPart() if @parts.last instanceof UtteranceSlot
      updateText: (event) ->
        return if event.target != @$refs.editable
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@updateUtterance, 1000)
      updateUtterance: (event) ->
        @selection = document.getSelection()
        @resetCaretPosition()
        @setCaretPositionDirection()
        @utterance.string = @$refs.editable.innerText
        # hier die Slots auslesen
        @resetParts()
        charIndex = 0
        @$refs.editable.childNodes.forEach (node) =>
          @setCaretPosition(node, charIndex)
          switch node.nodeName
            when '#text'
              text = node.textContent.replaceAll('​', '')
              @addTextPart(text)
              charIndex += text.length #if node.textContent != '​' # ignore zero-width space for caret position
            when 'SPAN'
              if slot = @utterance.slots.find (slot) -> slot.id == node.id
                slot.startPos = charIndex
                slot.text = @getText(node)
                @parts.push(slot)
              else
                @parts.push(new UtteranceSlot(startPos: charIndex, text: @getText(node)))
              charIndex += @getText(node).length
        @addEmptyTextPart() if @parts.last instanceof UtteranceSlot
        @utterance.slots = @parts.filter (part) -> part instanceof UtteranceSlot
        @utterance.string = @parts.map((part) ->
          if part instanceof UtteranceSlot
            part.text
          else
            part.replaceAll('​', '')
        ).join('')
        @catchRevision()
        @updateWithDisplay()
      onSelectionchange: ->
        @selection = document.getSelection()
        if !(@selection.anchorNode? && @selection.focusNode?)
          @noInserting()
          return
        isDirectlyInsideEditable = @$refs.editable == @selection.anchorNode.parentElement == @selection.focusNode.parentElement
        if !isDirectlyInsideEditable
          @noInserting()
          return
        isInsideTextNode = @selection.anchorNode == @selection.focusNode
        orderCode = @selection.anchorNode.compareDocumentPosition(@selection.focusNode)
        if orderCode == 0
          firstNode = @selection.anchorNode
          lastNode = @selection.anchorNode
          @startOffset = Math.min(@selection.anchorOffset, @selection.focusOffset)
          @endOffset = Math.max(@selection.anchorOffset, @selection.focusOffset)
        else if orderCode == 2
          firstNode = @selection.focusNode
          lastNode = @selection.anchorNode
          @startOffset = @selection.focusOffset
          @endOffset = @selection.anchorOffset
        else if orderCode == 4
          firstNode = @selection.anchorNode
          lastNode = @selection.focusNode
          @startOffset = @selection.anchorOffset
          @endOffset = @selection.focusOffset
        @selectedNodes = [firstNode]
        @selectedNodes.push(@selectedNodes.last.nextSibling) while @selectedNodes.last != lastNode
        allNodesTextOrCommentNodes = @selectedNodes.every (node) -> ['#text', '#comment'].includes(node.nodeName)
        @buttonActive = allNodesTextOrCommentNodes && @selection.toString().replaceAll('​', '').length > 0
      noInserting: ->
        @selectedNodes = []
        @buttonActive = false
        @startOffset = 0
        @endOffset = 0
      createSlot: ->
        precedingText = ''
        Array.from(@$refs.editable.childNodes).some (node) =>
          return true if node == @selectedNodes[0]
          precedingText += @getText(node).replaceAll('​', '')
          false
        selectedNodesText = @selectedNodes.map((node) => @getText(node)).join('')
        precedingText += selectedNodesText[0...@startOffset].replaceAll('​', '')
        newSlot = new UtteranceSlot(
          startPos: precedingText.length
          text: selectedNodesText.replaceAll('​', '')[@startOffset..(@endOffset - 1)]
          definition: @slotDefinition
        )
        @utterance.slots.push(newSlot)
        @buildParts()
        @selection.removeAllRanges()
        @$nextTick => @slotEditorRefs.find((editor) -> editor.slot.id == newSlot.id).edit()
        @update() if @slotDefinition
      removeSlot: (index) ->
        @utterance.slots = @utterance.slots.filter (slot) => slot.id != @parts[index].id
        @buildParts()
        @catchRevision()
        @update()
      focus: ->
        @$refs.editable.focus()
      setSlotEditorRef: (el) ->
        @slotEditorRefs.push(el) if el?
      update: ->
        @debounceTimeout = null
        @$emit('update:modelValue', @utterance)
      pasteMultiple: (event) ->
        clipboardData = event.clipboardData || window.clipboardData
        items = clipboardData.getData('Text').split('\n')
        firstItem = items.shift()
        document.execCommand('insertText', false, firstItem)
        @$emit('add', items)

    components:
      ActionMenu: ActionMenu
      Button: Button
      UtteranceSlotEditor: UtteranceSlotEditor
